import React from 'react'
import { Form } from 'antd';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';

const Files = props => {

    const routes = [{ label: 'Главная', path: '/' }, { label: 'Файлы', path: '/files' }];

    return (
        <>
            <PageBreadcrumbs routes={routes} />

            
            
            <Form className="che-form">
            <PageTitle title="Файлы" />
            
            <p style={{ marginTop : 10, marginBottom : 10 }}><font color="#0099ff"><a target="_blank" rel="nofollow" href="https://drive.google.com/drive/folders/1jwrYvBeytKD3eiLhAS295kKmQd7DnkaM?usp=sharing">Добавить файл</a></font></p>

            <a target="_blank"
            href="https://drive.google.com/drive/folders/1VS5rsWUZFB2uGbxgHuOR9tNsCyDuECUN">
            <img 
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAArlBMVEX///
            9SlOJId7EdNE/k5ORFjuHK2/RahLnLy8tKkeOputTPzcvQ0NDa3uQ6b67FzNjg5vBHdrFVgLfI0+QZLURylMF
            Ee7srTnc0XY45aJ4ZLENRkt9UmOkiPV1NfLXq6eZIgcU2YZRMidEpSnE9bad+nceescvN1Nw/Z5osXJQlUodD
            b6QxZ6QfN1QkQWMxWIWWsdRjm9+HpMru8vjZ4u5mjb6nuM9AhNHz8/K9xc88goU7AAAF7UlEQVR4nO2cbVfiP
            BBAeXNBWaspXai0VgSVF1cR3GXl//+xJ0iLUJNKk3Qm9Zn7zT17JHdnMkkmYSsVgiAIgiAIgiAIgiAIgiAIgi
            AIgiAIgvgmdF7uf+mwPHvFVsik04xcTaJoabHjWdQ0gNu8xBaRsRXUjOG7oqVRfOWCbq3GtKjVNr9lie0iZuk
            2a3yE+vDfY2eeuq4JPQ6L3AdsGRF/IkOCG8Vf2DYiOsxIim4Vm9g2IjqOMUFebrBtRJg0ZDVsGxFGDRm2jQgy
            JEMyxIcM8ym+XhaG8sHFrGFUHM37jgWGNRNHaekRO7pXimN5DLnjXxVFs4YFZmnkcsV7dMMC4WegZjNSmIulM
            eQwt/n3extyxSj/TCyVIZ/of767Ics/EdOGjDk2ke6xONqGjrtcXdjDauk6Rg2Z89C4uWnYAx/Mg8PMGbJobJ
            PelptxxEwZMmah4EZxbzLqGTorGwW54soxY8giOwW54keeahk6D9YaPjhmDC+wTaRcGDIcY4tIGRsyPMcWkXJ
            OhmT4fzBkJTBkGoasxh4nk6nFhtPJ5PHdUTWGE58zs9hwthngRDmGj75fCkPff1Qz3AreTqbjc1sZTye3W0Ul
            Q9+fPw6Cahie2EsYVoPB1dz3VQynvj+oloOB708VDGf+EHvkRzP0Z/kNL2blEeSKs4v8hr9D7GHnIPytYPgPe
            9S5+JffsHOCPehcnOSfhz9KZviDDMnQdsiQDO2HDMnQfsiQDO2HDMnQfnANQxnGPgHZcHB7JcFgtxLVMHjyJD
            wHpj4D1zDsenUx3q2xREWehz2JYd0zlqdYhnEWDmVBrD8Z+JB3kAyDeKKFt4XnKY5heNdPBJ6KzlMcw+C67m0
            vP8JuX6ZoqJ6iGIZ3Xt2bb4MY9grOU5wY7mfhUBbDet9InmIYhlfvYRvFP0mLTf1aW6+KYxjEIWJfFhsTeYpg
            +BG0+Gd5sTFRTxEMgyRm3lVSbKRT0UA9hTfcn3fxdbm82BjIU3jDvY3absWQFxv9egpuGPp7Nsk84zsAGdr1F
            NxweFBWRnEQM4qNbp5CG4bzg4Tsd2PFkXwqauYptGG6qCSHJPkxSreeAhumQri3YsiLjWaeAht+DtVTHKGMYq
            NXT2ENBWu75yfFRp6n1zp5Cms4EJTM+KCYtbPRylNQQ3HFjFeM1DpyqKiRp6CGA2Ei9ruxv7zY6Kz7oIbCENb
            7yWPOjGKTzFbLDcW1JNmcZhcb9TyFNBSH0Pt4j5tRbOrP9htK9p4fIaxmFxvVegpoKJ5lB+mXVWxU8xTMMGTi
            +PQOQpNRbFTrKVwMjwhh5jFKNU+hDDdNYBGj1Kizio03UFGEMpSk3+dN9VB2acpR2p8CGR4bws3f9KXMu/YaB
            uKub18wZOnrBcUHDDCGcR//cwhVhpwTGMNAUjq6AN++ATGUhVB5J5YHEENJT3t3NVMoEIayvdgTyDfEIAxls/
            AuNhx0j0VlawqSpekW4pbd+l3vZ6zy+yg13UAMhathEkLZZkBAz9pdm/Bccf11ozT9b6J0fgJa8QV90iuYEEL
            t2gTXErlDqNj6xtp5g4UQ7gQ8ShkmhVT6ECON9V2Mw25wcl4vPoSAfZrDjU3SQiw8hJD90j2Z3YVT8SHE6pfGI
            Sx6LYQ13Lv/3b0yOT6En9sdFhruPYYapv7gSzSugSENQxaHrAc3C3FuSJPrtPBYv/LckMabN9AQQr9U2DRsdk
            +9il8L4Q2r1WcekNgWJoTghgPPi7vAhR8qkAxD/zmZhfJbplSOaoUQ/m1ikDTy570jGek93cP7ZlfW/YTJr1v
            St2TJ0H7IkAzthwzJ0H7IkAzthwzJ0H7IUMBlyQwvcxu+mfvfcSAI3vIbvpxijzoHpy8Khu0F9rBzsGjnN6y0
            1tjDzsG6lV+w8rO9XpQjUU8X63ZDwfCt1W6/LKqntlNdvLTbLYUkrVQaLe64Xp3ZzWrN/VoqIdwqbiQtZzNIR
            cFNopYDpRSNuWn8tJ3GjYYfQRAEQRAEQRAEQRAEQRAEQRAEIP8B/yuLsTOjH1MAAAAASUVORK5CYII="/>
            </a>
            </Form>
        </>
        )
}

export default Files;