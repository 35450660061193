import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, notification, Input, Button, Spin, Comment, Avatar, Tooltip} from 'antd';
import { useState, useEffect } from 'react';
import { API_getDevData, API_getcom, API_addcomment, API_deleteDev } from './../api/che';
import { Link } from 'react-router-dom'
import { FormOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

var moment = require('moment');

const DevelopSingle = (props) => {

    const [devName, setdevName] = useState(undefined);
    const [devDescFull, setdevDescFull] = useState(undefined);

    const [devDate, setdevDate] = useState(undefined);
    const [devAuthor, setdevAuthor] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [dis, setdis] = useState(true);

    useEffect(() => {
            getDevData();
    }, []);

    const [com, setCom] = useState([]);

    useEffect(() => {
        API_getcom({ devId: props.match.params.id, comType: 1 }).then(data => setCom(data));
    }, []);


    const getDevData = () => {
        API_getDevData(props.match.params.id)
            .then(response => {
                        setdevName(response[0].devName);
                        setdevDescFull(response[0].devDescFull);
                        setdevDate(response[0].devCreated);
                        setdevAuthor(response[0].devAuthor);
                        if (response[0].devAuthor==localStorage.getItem('login')) {
                            setdis(false)
                        }
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
    };

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Разработки', path: `/development` },
    { label: props.match.params.id, path: `/viewdevelopment/${props.match.params.id}` },
    ]

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    comment: values.devComment,
                    devId: parseInt(props.match.params.id),
                    commType: 1,
                };
                API_addcomment(json)
                    .then(function(response) {
                        notification.success({ message: 'Комментарий добавлен' });
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const deleteDev = () => {

        API_deleteDev(props.match.params.id);

        props.history.push(`/development`);

        notification.success({ message: 'Удален' });
        
        window.location.reload();

    }

    return (
        <>

            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit}>
            <div align="right"> <Link to={`/profile/${devAuthor}`}><p>{'Добавлено пользователем:  '+devAuthor+'  '+ moment(devDate).utc().format('DD.MM.YYYY HH:mm:ss')}</p></Link></div>
            <PageTitle title={devName}/>
            <Spin spinning={loading}>
                <Form.Item style={{ marginBottom: 0 }}>
                <div align="right">
                    <Button disabled={dis} icon={<EditOutlined />} type="primary" onClick={() => props.history.push(`/developmentedit/${props.match.params.id}`)}>
                            Редактировать
                    </Button>

                    <Button disabled={dis} icon={<DeleteOutlined />} type="danger" onClick={() => deleteDev()} style={{ marginLeft: "10px" }} >
                            Удалить
                    </Button>
                </div>    
                </Form.Item>    
                <Form.Item >
                    <div dangerouslySetInnerHTML={{__html: devDescFull}}/>
                </Form.Item>      
                <Form.Item style={{ marginBottom: 0 }}>
                    {com.map(comments => (
                        <div>
                            <Comment
                            author={<Link to={`/profile/${comments.author}`}><a>{comments.author}</a></Link>}
                            avatar={
                                <Link to={`/profile/${comments.author}`}>
                                    <Avatar
                                src={comments.profileAvatar}
                            />
                            </Link>
                            }
                            content={
                            <p>
                                {comments.comment}
                            </p>
                            }
                            datetime={
                            <Tooltip title={moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}</span>
                            </Tooltip>
                            }
                        />
                        </div>
                    ))}
                </Form.Item>    
                <Form.Item style={{ marginBottom: 0 }} name="devComment" rules={[{required: true, message: 'Введите комментарий'}]}>
                    <Input.TextArea placeholder="Комментарий" style={{width: '50%'}}/>
                </Form.Item>                
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button icon={<FormOutlined />} type="primary" htmlType="submit">
                            Отправить
                    </Button>
                </Form.Item>   
            </Spin>
            </Form>
        </>
    )
}

export default DevelopSingle;