import axios from './../utils/axios';
import { notification } from 'antd';

export const API_getevent = (json) => {
    let url = `che/getevent?`;
    if (json) {
        if (json.eventDate) url += `&eventDate=${json.eventDate}`;
        if (json.eventId) url += `&eventId=${json.eventId}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_addevent = json => {
    return axios({
        method: 'POST',
        url: `che/addevent`,
        data: {
            eventDesc: json.eventDesc,
            eventDescFull: json.eventDescFull,
            eventDate: json.eventDate,
            eventPlace: json.eventPlace,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deleteEvent = idEvent => {
    return axios({
        method: 'DELETE',
        url: `che/delevent/${idEvent}`,
    });
};
