import React from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { API_login } from './../api/auth';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions';
import { useState } from 'react';
import Registration from './Registration';
import ForgetPassword from './ForgetPassword';
import { UserOutlined, LockOutlined} from '@ant-design/icons';


const LoginForm = props => {

    const [DrawerReg, setDrawerReg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [DrawerForPass, setDrawerForPass] = useState(false);

    const handleSubmit = values => {
        setLoading(true);
                API_login(values.username, values.password)
                    .then(response => {
                        props.setUser(values.username);
                        localStorage.setItem('accessToken', response.accessToken);
                        localStorage.setItem('login', values.username);
                        notification.success({ message: `Добро пожаловать ${values.username}!` });
                        props.history.push('/');
                    })
                    .catch(() => {
                        notification.error({ message: 'Пользователь с таким логином или паролем не найден' });
                    })
                    .finally(() => setLoading(false));
    };

    const regBack = () => {

        setDrawerReg(!DrawerReg)

    }

    const ForgetPass = () => {

        setDrawerForPass(!DrawerForPass)

    }

    return (
        <Spin spinning={loading}>
        <Form style={{ width: 300, margin: '0 auto' }} onFinish={handleSubmit}>
            <p style={{ fontWeight: 300, textAlign: 'center', fontSize: 20, marginBottom: 10 }}>Авторизация</p>
            <Form.Item >
                <Form.Item style={{ marginBottom: 7 }} name="username" rules={[{required: true, message: 'Введите email'}]}>
                    <Input autoFocus prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="введите email" size="large" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 7 }} name="password" rules={[{required: true, message: 'Введите пароль'}]}>
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password" placeholder="введите пароль" size="large" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" htmlType="submit"  block size="large">
                        Вход
                    </Button>
                </Form.Item>
            </Form.Item>
            {
              DrawerReg && <Registration />
            }
            {
              DrawerForPass && <ForgetPassword />
            }
            <Button type="link" onClick={regBack} block size="small">
                Отправить заявку на вступление в Совет
            </Button>
            <Button style={{ marginTop: "10px" }} type="link" onClick={ForgetPass} block size="small">
                    Забыли пароль?
            </Button>
        </Form>
        </Spin>
    );
};

export default connect(
    null,
    { setUser }
)(LoginForm);
