import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, notification, Spin, DatePicker } from 'antd';
import {  API_uploadfile , API_commentParentUpdate} from './../api/che';
import {  API_getevent, API_deleteEvent, API_addevent } from './../api/calendar';
import { API_url } from './../api/const';
import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { SaveOutlined} from '@ant-design/icons';

const EventEdit = (props) => {

    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Календарь', path: `/calendar` },
        { label: 'Редактирование', path: `/event/${props.match.params.id}` },
        ]

     function uploadImageCallBack(file) {
      return new Promise(
          (resolve, reject) => {

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

                const json = {
                    fileBody: fileSource,
                };

                API_uploadfile(json)
                .then(function(response) {
                    resolve({ data: { link: API_url+"/che/download/"+response.IdFile } });
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
            }
          }
        );
      }

    const [loading, setLoading] = useState(false);

    const [editorState, setEditorState] = useState(undefined);
    const [eDate, seteDate] = useState(undefined);
    const [eventDate, seteventDate] = useState(undefined);

    useEffect(() => {
        getEvent();
    }, []);

    const getEvent = () => {
        setLoading(true);
        API_getevent({
            eventId: props.match.params.id,
        }).then(data => {
                        seteventDate(data[0].eventDate);
                        form.setFieldsValue({
                            eventDesc: data[0].eventDesc,
                            eventPlace: data[0].eventPlace,
                        })
                         setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray((htmlToDraft(data[0].eventDescFull)).contentBlocks)))

            })
            .finally(() => setLoading(false));
    };

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    eventPlace: values.eventPlace,
                    eventDesc: values.eventDesc,
                    eventDate: eDate,
                    eventDescFull: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                };
                API_addevent(json)
                    .then(response => {
                        notification.success({ message: 'Данные сохранены' });
                        const json = {
                            oldId: props.match.params.id,
                            newId: response.id,
                        };
                        API_commentParentUpdate(json);
                        API_deleteEvent(props.match.params.id);
                        props.history.push(`/calendar`);
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    function onChange(dateString) {

        seteDate(dateString.format('DD-MM-YYYY HH:mm:ss'));

    }

    const [form] = Form.useForm();

    const editorStyle = {
        border: '1px solid black',
        padding: '5px',
        borderRadius: '2px',
        width: '100%',
      };


    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} form={form} layout='vertical'>
                <PageTitle title={"Редактирование"}/>
                <Spin spinning={loading}>
                    <Form.Item label="Название" style={{ marginBottom: 0 }} name="eventDesc" rules={[{required: true, message: 'Введите название'}]}>
                                   <Input placeholder="введите название" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Место" style={{ marginBottom: 0 }} name="eventPlace" rules={[{required: true, message: 'Введите место'}]}>
                                    <Input placeholder="введите место" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Дата" style={{ marginBottom: 0 }} name="eventDate" rules={[{required: true, message: 'Введите Дату'}]}>
                               <DatePicker showTime placeholder="Select Time" onChange={onChange} />
                    </Form.Item>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorStyle={editorStyle}
                        onEditorStateChange={setEditorState}
                        toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: { uploadCallback: uploadImageCallBack,
                                previewImage: true,
                                defaultSize: {
                                height: 'auto',
                                width: '100%',
                        }},
                        }}
                    />
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default EventEdit;