import axios from './../utils/axios';
import { notification } from 'antd';

export const API_telsendmessage = (message) => {
    return axios({
        method: 'GET',
        url: `/che/Tsendmessage/${message}`,
    })
        .then(function(response) {
                return response
        })
        .catch(function(error) {
            notification.error({ message: `Ошибка при отправке сообщения` });
        });
};

export const API_telgetdmessage = () => {
        
    return axios({
        method: 'GET',
        url: `/che/Tgetdmessages`,
    })
        .then(function(response) {
                return response.data
        })
        .catch(function(error) {
            notification.error({ message: `Ошибка при получении данных` });
        });
}