import axios from './../utils/axios';
import { notification } from 'antd';

export const API_registration = (email, rkey, rpass, login, rfio, rvuz, rpost, rname, rlname, rphone, rlink, gmail) => {
    return axios({
        method: 'POST',
        url: `che/registration`,
        data: {
            email: email,
            rkey: rkey,
            rpass: rpass,
            login: login,
            rfio: rfio,
            rvuz: rvuz,
            rpost: rpost,
            rname: rname,
            rlname: rlname,
            rphone: rphone,
            rlink: rlink,
            gmail: gmail,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};

export const API_checkreg = (email, rkey, login) => {
    return axios({
        method: 'POST',
        url: `che/checkreg`,
        data: {
            email: email,
            rkey: rkey,
            login: login,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};