import React from 'react'
import { List, Form, Spin, Button} from 'antd';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { API_checkreg } from './../api/registration';
import { useState, useEffect } from 'react';
import { API_getnews } from './../api/news';
import { Link } from 'react-router-dom'
import { PlusOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';

var moment = require('moment');

const News = props => {

    const [loading, setLoading] = useState(false);

    const [newsdata, setnewsdata] = useState([]);

    const routes = [{ label: 'Главная', path: '/' }, { label: 'Новости', path: '/news' }];

    useEffect(() => {
        getnews();

        API_checkreg(localStorage.getItem('login'), 'null', 'null')
            .then(response => {
                if(response != undefined) {
                    if (localStorage.getItem('login') && response[0].email in (1,2)) {
                                    
                    } else {
                        localStorage.removeItem('accessToken')
                        props.history.push('/login')
                    }
                }
            });

    }, []);

    const getnews = () => {
        setLoading(true);
        API_getnews().then(data => { setnewsdata(data)} )
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
                <Form className="che-form">
                <PageTitle title="Новости" />
                <div align="right">
                <Button icon={<PlusOutlined />} onClick={() => props.history.push('/addnews')} type="primary">
                        Добавить новость
                </Button>
                </div>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={newsdata}
                    pagination={{
                    pageSize: 5,
                    }}
                    renderItem={item => (
                        <Link to={`/viewnews/${item.newsId}`}>

                    <List.Item
                        key={item.newsTitle}
                        extra={
                        <img
                            width={272}
                            alt="logo"
                            src={item.newsTitleImage}
                        />
                        }
                    >
                        <List.Item.Meta

                        title={<font color="#4E59F1"><a>{item.newsTitle}</a></font>}

                        description={'Добавлено пользователем:  '+item.newsAuthor+'  '+ moment(item.createdate).utc().format('DD.MM.YYYY HH:mm:ss')}
                        />

                        {item.newsType==3 && <p><ExclamationCircleTwoTone twoToneColor="#eb2f96" /> <font color="#eb2f96">Новость будет опубликована после проверки</font></p>}

                        {item.newsDesc}
                        

                    </List.Item>
                    </Link>
                    )
                }
                />
                </Form>
            </Spin>
        </>
        )
}

export default News;