import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, notification, Spin } from 'antd';
import { API_addforum} from './../api/forum';
import { useState } from 'react';
import { SaveOutlined} from '@ant-design/icons';

const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Форум', path: `/forum` },
    { label: 'Новая тема', path: `/forumadd` },
    ]

const ForumAdd = (props) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    forumTitle: values.forumTitle,
                    forumDescFull: values.forumDescFull,
                };
                API_addforum(json)
                    .then(function(response) {
                        notification.success({ message: 'Данные сохранены' });
                        props.history.push('/forum');
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical'>
            <PageTitle title={"Новая тема форума"}/>
            <Spin spinning={loading}>
               <Form.Item label="Тема" style={{ marginBottom: 0 }} name="forumTitle" rules={[{required: true, message: 'Введите Тему'}]}>
                                <Input placeholder="введите Тему" maxLength="250"/>
                </Form.Item>
                <Form.Item label="Сообщение" style={{ marginBottom: 0 }} name="forumDescFull" rules={[{required: true, message: 'Введите сообщение'}]}>
                                <Input.TextArea placeholder="Сообщение"/>
                </Form.Item>
                <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                    Сохранить
                </Button>
            </Spin>    
            </Form>
        </>
    )
}

export default ForumAdd;