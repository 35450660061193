import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Input, Spin, Button, notification, Upload, message} from 'antd';
import { API_updateprofile, API_getdataprofile } from './../api/profile';
import { useState, useEffect } from 'react';
import { API_uploadfile} from './../api/che';
import { API_url } from './../api/const';
import ChangePassword from './../components/ChangePassword';
import { LoadingOutlined, PlusOutlined, SaveOutlined, KeyOutlined } from '@ant-design/icons';

const Profile = (props) => {

    const [dis, setdis] = useState(true);

    const [loading, setLoading] = useState(false);
    const [imageUrl, setimageUrl] = useState(undefined);

    const [DrawerV, setDrawerV] = useState(false);


    const routes = [{ label: 'Главная', path: '/' }, { label: 'Профиль: '+props.match.params.login, path: `/profile/${props.match.params.login}` }]

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    profileFio: values.profileFio,
                    profileVuz: values.profileVuz,
                    profileDesc: values.profileDesc,
                    profilePost: values.profilePost,
                    profileEmail: values.profileEmail,
                    profileName: values.profileName,
                    profileLname: values.profileLname,
                    profilePhone: values.profilePhone,
                    profileLink: values.profileLink,
                    profileAvatar: imageUrl,
                };
                API_updateprofile(json)
                    .then(function(response) {
                        notification.success({ message: 'Данные сохранены' });
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status === 'done') {
        var reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => {

            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

            const json = {
                fileBody: fileSource,
            };

            API_uploadfile(json)
            .then(function(response) {
                setimageUrl(API_url+"/che/download/"+response.IdFile)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
        }
        setLoading(false);
        }
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    const getProfileData = () => {
        API_getdataprofile(props.match.params.login)
            .then(response => {
                        setimageUrl(response[0].profileAvatar);
                        form.setFieldsValue({
                            profileFio: response[0].profileFio,
                            profileVuz: response[0].profileVuz,
                            profilePost: response[0].profilePost,
                            profileEmail: response[0].profileEmail,
                            profileDesc: response[0].profileDesc,
                            profileName: response[0].profileName,
                            profileLname: response[0].profileLname,
                            profilePhone: response[0].profilePhone,
                            profileLink: response[0].profileLink,
                        });
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
    };

    const vizDrawerV = () => {

        setDrawerV(!DrawerV)

    }

    const [form] = Form.useForm();

    useEffect(() => {
        getProfileData();
        if (props.match.params.login==localStorage.getItem('login')) {
            setdis(false)
        }
    }, []);

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
                <Form className="che-form" onFinish={handleSubmit} form={form} layout='vertical'>
                    <PageTitle title="Профиль" />
                    <Form.Item label="Фамилия" style={{ marginBottom: 0 }} name="profileFio" rules={[{required: true, message: 'Введите Фамилию'}]}>
                        <Input placeholder="введите Фамилию" maxLength="250" disabled={dis} />
                    </Form.Item>
                    <Form.Item label="Имя" style={{ marginBottom: 0 }} name="profileName" rules={[{required: true, message: 'Введите Имя'}]}>
                        <Input placeholder="введите Имя" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Отчество" style={{ marginBottom: 0 }} name="profileLname" rules={[{required: true, message: 'Введите Отчество'}]}>
                       <Input placeholder="введите Отчество" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="ВУЗ" style={{ marginBottom: 0 }} name="profileVuz" rules={[{required: true, message: 'Введите ВУЗ'}]}>
                       <Input placeholder="введите вуз" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Должность" style={{ marginBottom: 0 }} name="profilePost" rules={[{required: true, message: 'Введите должность'}]}>
                        <Input placeholder="введите должность" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Email" style={{ marginBottom: 0 }} name="profileEmail" rules={[{required: true, message: 'Введите Email'}]}>
                        <Input placeholder="введите Email" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Номер телефона" style={{ marginBottom: 0 }} name="profilePhone" rules={[{required: true, message: 'Введите Номер телефона'}]}>
                        <Input placeholder="введите Номер телефона" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Ссылка на личный профиль на сайте вуза" style={{ marginBottom: 0 }} name="profileLink" rules={[{required: true, message: 'Введите Ссылку'}]}>
                        <Input placeholder="введите Номер телефона" maxLength="250" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Дополнительная информация" style={{ marginBottom: 0 }} name="profileDesc" rules={[{required: false, message: 'Информация'}]}>
                        <Input.TextArea placeholder="Информация" disabled={dis}/>
                    </Form.Item>     
                        <Upload 
                            disabled={dis}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : 
                            <div>
                                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                <div className="ant-upload-text">Фото</div>
                            </div>}
                        </Upload>
                    {
                         DrawerV && <ChangePassword />
                    }
                    <Button disabled={dis} icon={<SaveOutlined />} htmlType="submit" type="primary">
                            Сохранить
                    </Button> 
                    <Button disabled={dis} icon={<KeyOutlined />} onClick={vizDrawerV} type="primary" style={{ marginLeft: "10px" }}>
                            Сменить пароль
                    </Button> 
                </Form>
            </Spin>
        </>
    )
}

export default Profile;