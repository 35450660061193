import React from 'react'
import { Form, Button, notification, Spin, Input} from 'antd';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react'; 
import { API_telsendmessage, API_telgetdmessage } from './../api/telegram';
import Moment from 'react-moment';

const Chat = props => {

    const [loading, setLoading] = useState(false);
    const [update, setupdate] = useState(false);
    const [GroupMessage, setGroupMessage] = useState([]);

    const SendM = values => {
            setLoading(true);
                API_telsendmessage(localStorage.getItem('login')+": "+values.tMessage)
                .then(function(response) {
                        setupdate(!update)
                        form.resetFields();
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false))
            ;
    };


    const getData = () => {
        setLoading(true);
        API_telgetdmessage()
        .then(data => {
            setGroupMessage(data)
            updateScroll()
        }
            )
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        getData()
    }, [update]);

    const updateScroll = () => {
        var messageBody = document.querySelector('#messageBody');

        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }

    const routes = [{ label: 'Главная', path: '/' }, { label: 'Чат', path: '/chat' }];

    Moment.globalFormat = 'D MMM YYYY HH:mm:ss';

    const [form] = Form.useForm();

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
                <Form className="che-form" form={form} onFinish={SendM}>
                <PageTitle title="Чат (v 1.0)" />
                <div>Все что тут написано дублируются в Telegram канал 'Руководители IT служб вузов'</div>
                <Form.Item className="chat-form">
                <div class="messageBodyscroll" id="messageBody">
                        {GroupMessage.map(item => ( 
                            <div><Moment unix>{item.msgDate}</Moment>{"/ " +item.msgAuthor+": "+item.msgText}</div>
                        ))}
                </div>
                </Form.Item>
                <Form.Item style={{ marginBottom: 10 }} name="tMessage" rules={[{required: true, message: 'Введите cообщение'}]}>
                    <Input.TextArea placeholder="Сообщение" maxLength="250" autofocus="true"/>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                        Отправить сообщение
                </Button>

                </Form>
            </Spin>
        </>
        )
}

export default Chat;