import axios from './../utils/axios';
import { notification } from 'antd';

export const API_getdev = (json) => {
    let url = `che/getdev?`;
    if (json) {
        if (json.devName) url += `&devName=${json.devName}`;
        if (json.devVuz) url += `&devVuz=${json.devVuz}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getDevData = devID => {
        return axios({
            method: 'GET',
            url: `che/getdev/${devID}`,
        })
            .then(function(response) {
                return response.data;
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            });
};

export const API_getDevVuz = () => {
    return axios({
        method: 'GET',
        url: `che/getvuz`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_devadd = json => {
    return axios({
        method: 'POST',
        url: `che/adddev`,
        data: {
            devName: json.devName,
            devVuz: json.devVuz,
            devDesc: json.devDesc,
            devDescFull: json.devDescFull,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getcom = (json) => {
    let url = `che/getcomment?`;
    if (json) {
        if (json.devId) url += `&devId=${json.devId}`;
        if (json.comType) url += `&comType=${json.comType}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_addcomment = json => {
    return axios({
        method: 'POST',
        url: `che/addcomment`,
        data: {
            comment: json.comment,
            devId: json.devId,
            commType: json.commType,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_uploadfile = json => {
    const url = `che/upload`;
    return axios({
        method:  'POST',
        url: url,
        data: {
            fileBody: json.fileBody
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deleteDev = idDev => {
    return axios({
        method: 'DELETE',
        url: `che/deletedev/${idDev}`,
    });
};

export const API_commentParentUpdate = (json) => {
    let url = `che/commentPupdate?`;
    if (json) {
        if (json.oldId) url += `&oldId=${json.oldId}`;
        if (json.newId) url += `&newId=${json.newId}`;
    }

    return axios({
        method: 'POST',
        url: url,
    });
};
