import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, notification, Spin } from 'antd';
import { API_postlink } from './../api/link';
import { useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';

const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Полезные ссылки', path: `/links` },
    { label: 'Добавление ссылки', path: `/addlink` },
    ]

const LinkAdd = (props) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    linkName: values.linkName,
                    link: values.link,
                };
                API_postlink(json)
                    .then(function(response) {
                        notification.success({ message: 'Данные сохранены' });
                        props.history.push('/links');
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка, попрбуйте позже' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };


    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical'>
                <PageTitle title={"Добавить ссылку"}/>
                <Spin spinning={loading}>
                    <Form.Item label="Название ссылки" style={{ marginBottom: 0 }} name="linkName" rules={[{required: true, message: 'Введите название'}]}>
                                   <Input.TextArea placeholder="введите название"/>
                    </Form.Item>
                    <Form.Item label="Ссылка" style={{ marginBottom: 0 }} name="link" rules={[{required: true, message: 'Введите ссылку'}]}>
                                   <Input.TextArea placeholder="Введите ссылку"/>
                    </Form.Item>

                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Добавить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default LinkAdd;