import React from 'react'
import { Form, Spin, Button } from 'antd';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react'; 
import { API_getlinks } from './../api/link';
import { PlusOutlined} from '@ant-design/icons';

const Links = props => {

    const routes = [{ label: 'Главная', path: '/' }, { label: 'Полезные ссылки', path: '/links' }];

    const [linkdata, setlinkdata] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        API_getlinks().then(data => { setlinkdata(data)})
        .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
                <Form className="che-form">
                <PageTitle title="Полезные ссылки" />
                <div align="right">
                <Button icon={<PlusOutlined />} onClick={() => props.history.push('/addlink')} type="primary">
                        Добавить ссылку
                </Button>
                </div>
                    {linkdata.map(linkdata => (
                        <p class="callinfo" align="justify">
                            <font color="#0099ff"><a target="_blank" href={linkdata.link}>{linkdata.linkName}</a></font>
                        </p>
                    ))}
                </Form>
            </Spin>    
        </>
        )
}

export default Links;