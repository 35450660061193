import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Button, Spin, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getforum } from './../api/forum';
import { PlusOutlined} from '@ant-design/icons';

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, { label: 'Форум', path: '/forum' }];

const columns = [
    {
        title: 'Темы',
        key: 'forumTitle',
        dataIndex: 'forumTitle',
        width: '80%',
        align: 'center',
    },
    {
        title: 'Автор',
        key: 'forumAuthor',
        dataIndex: 'forumAuthor',
        width: '10%',
    },
    {
        title: 'Обновлено',
        width: '10%',
        align: 'center',
        render: row => {
            const date = moment(row.updateDate)
                .utc()
                .format('DD.MM.YYYY');
            const time = moment(row.updateDate)
                .utc()
                .format('HH:mm:ss');
            return (
                <>
                    <p style={{ fontSize: 14, fontWeight: 500 }}>{date}</p>
                    <p style={{ fontSize: 12, marginRight: 5, fontStyle: 'italic' }}>{time}</p>
                </>
            );
        },

    },
];

const Forum = props => {
    const [loading, setLoading] = useState(false);
    const [forumdata, setforumdata] = useState([]);


    useEffect(() => {
        getforum();
    }, []);


    const getforum = () => {
        setLoading(true);
        API_getforum().then(data => {
                setforumdata(data);
            })
            .finally(() => setLoading(false));
    };
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            
            <Form className="che-form">
                <PageTitle title="Форум" />
                <div align="right">
                    <Button icon={<PlusOutlined />} onClick={() => props.history.push('/forumadd')} type="primary">
                        Добавить
                    </Button>
                </div>    
                <Spin spinning={loading}>
                    <Table
                        columns={columns}
                        dataSource={forumdata}
                        rowClassName="row-hover"
                        pagination={{
                            pageSize: 100,
                            position: 'both',
                        }}
                        onRow={getforumData => {
                            return {
                                onClick: () => props.history.push(`/viewforum/${getforumData.forumId}`),
                            };
                        }}
                    />
                </Spin>
            </Form>
        </>
    );
};

export default withRouter(Forum);
