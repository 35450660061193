import React from 'react';
import { Menu, Layout } from 'antd';
import Header from './main/Header';
import { withRouter } from 'react-router-dom';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Development from '../pages/Development';
import DevelopmentAdd from '../pages/DevelopmentAdd';
import DevelopSingle from '../pages/DevelopSingle';
import DevelopmentEdit from '../pages/DevelopmentEdit';
import Footer from './main/Footer';
import Profile from './../pages/Profile';
import Kalendar from './../pages/Calendar';
import KalendarDate from './../pages/CalendarDate';
import Event from './../pages/Event';
import EventAdd from './../pages/EventAdd'; 
import EventEdit from './../pages/EventEdit';
import News from './../pages/News'; 
import Forum from './../pages/Forum';
import ForumSingle from './../pages/ForumSingle';
import ForumAdd from './../pages/ForumAdd';
import Files from './../pages/Files';
import About from './../pages/About';
import NewsSingle from './../pages/NewsSingle'; 
import NewsAdd from './../pages/NewsAdd';
import NewsEdit from '../pages/NewsEdit';
import Chat from '../pages/Chat';
import { ReadOutlined, RocketOutlined, CalendarOutlined, UnorderedListOutlined, FolderOutlined, TeamOutlined, AndroidOutlined, LinkOutlined} from '@ant-design/icons';
import Links from '../pages/Links';
import LinkAdd from '../pages/LinkAdd';

const Main = props => {
    return (
        <div className="wrapper">
            <div className="content">
                <Header history={props.history} />
                <Layout>
                        <Menu
                            mode="horizontal"
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                            <Menu.Item key="/news">
                                <NavLink to="/news">
                                    <ReadOutlined /> <span>Новости</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/development">
                                <NavLink to="/development">
                                    <RocketOutlined /> <span>Разработки</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/calendar">
                                <NavLink to="/calendar">
                                    <CalendarOutlined /> <span>Календарь событий</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/forum">
                                <NavLink to="/forum">
                                    <UnorderedListOutlined /> <span>Форумы</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/files">
                                <NavLink to="/files">
                                    <FolderOutlined /> <span>Файлы</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/chat">
                                <NavLink to="/chat">
                                    <AndroidOutlined /> <span>Чат</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/links">
                                <NavLink to="/links">
                                    <LinkOutlined /> <span>Полезные ссылки</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/about">
                                <NavLink to="/about">
                                    <TeamOutlined /> <span>О нас</span>
                                </NavLink>
                            </Menu.Item>
                        </Menu>
                </Layout>   
                    <Layout style={{ padding: '0 24px 24px', background: '#FFF' }}>
                        <Route exact path="/" render={() => <Redirect to="/news" />} />
                        <Switch>
                            <Route path="/development" component={Development} />
                            <Route path="/viewdevelopment/:id" component={DevelopSingle} />
                            <Route path="/profile/:login" component={Profile} />
                            <Route path="/developmentadd" component={DevelopmentAdd} />
                            <Route path="/developmentedit/:id" component={DevelopmentEdit} />
                            <Route path="/calendar" component={Kalendar} />
                            <Route path="/viewcalendar/:date" component={KalendarDate} />
                            <Route path="/event/:id" component={Event} />
                            <Route path="/eventadd" component={EventAdd} />
                            <Route path="/eventedit/:id" component={EventEdit} />
                            <Route path="/news" component={News} />
                            <Route path="/viewnews/:id" component={NewsSingle} />
                            <Route path="/forum" component={Forum} />
                            <Route path="/viewforum/:id" component={ForumSingle} />
                            <Route path="/forumadd" component={ForumAdd} />
                            <Route path="/files" component={Files} />
                            <Route path="/about" component={About} />  
                            <Route path="/addnews" component={NewsAdd} /> 
                            <Route path="/editnews/:id" component={NewsEdit} />
                            <Route path="/chat" component={Chat} />
                            <Route path="/links" component={Links} />
                            <Route path="/addlink" component={LinkAdd} /> 
                        </Switch>
                    </Layout>
                
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(props => <Main {...props} />);
