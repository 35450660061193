import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from './../components/LoginForm';
import { Carousel, Button, Drawer, Form } from 'antd';
import { useState } from 'react';
import Registration from './../components/Registration';
import Footer from './main/Footer';

const Login = props => {

    const [DrawerLogin, setDrawerLogin] = useState(false);

    const regLogin = () => {

        setDrawerLogin(!DrawerLogin)

    }

    const DrawerClose = () => {
        setDrawerLogin(false)
      };


    const [DrawerReg, setDrawerReg] = useState(false);

    const regBack = () => {

        setDrawerReg(!DrawerReg)

    }

    return (
        <div>
            <Carousel autoplay>
                    <div>
                        <p style={{ textAlign: 'center', fontSize: '26px', marginBottom: '20px', marginTop: '20px', fontWeight: 500 }}>Совет ИТ-руководителей вузов России</p>
                    </div>
                    <div>
                        <p style={{ textAlign: 'center', fontSize: '26px', fontWeight: 300, marginTop: '20px' }}>Council of IT Heads of Russian Universities</p>
                    </div>
                    <div>
                        <p style={{ textAlign: 'center', fontSize: '26px', marginBottom: '20px', marginTop: '20px', fontWeight: 500 }}>Совет ИТ-руководителей вузов России</p>
                    </div>
                    <div>
                        <p style={{ textAlign: 'center', fontSize: '26px', fontWeight: 300, marginTop: '20px' }}>Council of IT Heads of Russian Universities</p>
                    </div>
            </Carousel>
            <div align="right" style={{ marginTop: '20px', marginRight: '20px'}}>
                        <Button type="primary" onClick={regLogin}>
                          Авторизация
                        </Button>
                        {
                            DrawerReg && <Registration />
                        }
            </div>
                <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginBottom: 100,
                        }}
                    > 
                        <p style={{ textAlign: 'center', fontSize: '26px', marginBottom: '20px', fontWeight: 500, marginTop: '60px' }}>Совет ИТ-руководителей вузов России</p>
                        <p style={{ textAlign: 'center', fontSize: '26px', fontWeight: 300, marginBottom: '20px'}}>Council of IT Heads of Russian Universities</p>
                        <Form className="che-form">
                        <div align="justify">
                            Данный портал является рабочей площадкой Совета ИТ-руководителей университетов России, созданного для организации совместной работы в 
                            области цифрового развития, обмена опытом, развития сотрудничества, выявления общих проблем и нахождения путей их решения.
                            Портал также является площадкой обмена мнениями и обсуждения кейсов в области цифровой трансформации университетов. Подробнее о цели, 
                            задачах и принципах работы Совета можно узнать в&nbsp;
                            <font color="#0099ff"><a target="_blank" href="https://drive.google.com/file/d/1B_cmrYd4rbhsNIbDwJekwPv3WjP_Spsm/view?usp=sharing">Положении о Совете ИТ-руководителей вузов России</a></font>
                        </div>
                        </Form>
                        <Drawer
                            width={"80%"}
                            title="Авторизация"
                            visible={DrawerLogin}
                            onClose={DrawerClose}
                            bodyStyle={{ paddingBottom: 80 }}
                            >
                            <LoginForm history={props.history} />
                        </Drawer>
                        <Button type="primary" onClick={regBack}>
                          Отправить заявку на вступление в Совет
                        </Button>
                    </div>
                </div>
            <Footer />    
        </div>
    );
};

export default withRouter(props => <Login {...props} />);
