
import React from 'react';
import './..//App.css';
import { Calendar, Badge, Form, Spin, notification, Button, Tooltip } from 'antd';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useState, useEffect } from 'react';
import { API_getevent } from './../api/calendar';
import { PlusOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom'

const routes = [{ label: 'Главная', path: '/' }, { label: 'Календарь', path: '/calendar' }]

const Kalendar = (props) => {

    const [loading, setLoading] = useState(false);
    const [eventData, seteventData] = useState([]);

    const getEvent = () => {
    setLoading(true);
    API_getevent().then(data => {
          seteventData(data);
        })
      .catch(function(error) {
          try {
              notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
          } catch {
              notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
          }
      })
        .finally(() => setLoading(false));
    };
    
    useEffect(() => {
      getEvent();
    }, []);
    

    function getListData(value) {
      let listData = new Array();
      let i = 0
      for (var key in eventData) {
        var obj = eventData[i]
        if (key='eventDate') {
          if (obj[key]==value.format('DD.MM.YYYY')){
            listData.push(obj)
          }
        }
        i++
      }
      return listData || [];
    }
    
    function dateCellRender(value) {
      const listData = getListData(value);
      return (
        <ul className="events">
          {listData.map(item => (
            <li key={item.eventType}>
              <Link to={`/viewcalendar/${item.eventDate}`}><Tooltip title={item.eventDesc} ><Badge status={item.eventType} text={item.eventDesc} /></Tooltip></Link>
            </li>
          ))}
        </ul>
      );
    }
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Календарь" />
            <Spin spinning={loading}>
              <div align="right">
                  <Button icon={<PlusOutlined />} onClick={() => props.history.push('/eventadd')} 
                  type="primary">
                      Добавить событие
                  </Button>
              </div>
              <Calendar  dateCellRender={dateCellRender} />
            </Spin>  
        </>
    )
}

export default Kalendar;