import axios from './../utils/axios';
import { notification } from 'antd';

export const API_updateprofile = json => {
    return axios({
        method: 'POST',
        url: `che/updateprofile`,
        data: {
            profileFio: json.profileFio,
            profileVuz: json.profileVuz,
            profileDesc: json.profileDesc,
            profilePost: json.profilePost,
            profileEmail: json.profileEmail,
            profileAvatar: json.profileAvatar,
            profileName: json.profileName,
            profileLname: json.profileLname,
            profilePhone: json.profilePhone,
            profileLink: json.profileLink,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getdataprofile = login => {
    return axios({
        method: 'GET',
        url: `che/getdataprofile/${login}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getMembers = () => {
    return axios({
        method: 'GET',
        url: `che/getmembers`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};