import React from 'react'

const Footer = () => (
    <div style={{ paddingLeft: 20,height: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '##4C4D5A', flex: '0 0 auto', color: '#B2ABAB' }}>
        <p>© Cовет ИТ-руководителей вузов России, 2020. Все права защищены.</p>
        <p>
            <p style={{ marginRight: 20 }}>Разработка и поддержка решения — ДЦТ СВФУ им. М.К.Аммосова</p>
            <p style={{ marginRight: 20, marginTop : 10 }}>Техническая поддержка сайта: <font color="#0099ff"><a href="mailto:it.vuzov@gmail.com">it.vuzov@gmail.com</a></font></p>
        </p>
    </div>
)

export default Footer
