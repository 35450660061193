import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, notification, Input, Button, Spin, Comment, Avatar, Tooltip} from 'antd';
import { useState, useEffect } from 'react';
import { API_getcom, API_addcomment } from './../api/che';
import { API_getevent, API_deleteEvent } from './../api/calendar';
import { Link } from 'react-router-dom'
import { FormOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

var moment = require('moment');

const Event = (props) => {

    const [eventName, seteventName] = useState(undefined);
    const [devEventcFull, seteventDescFull] = useState(undefined);
    const [eventDate, seteventDate] = useState(undefined);

    const [eventAuthor, seteventAuthor] = useState(undefined);
    const [eventCreateDate, seteventCreateDate] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [dis, setdis] = useState(true);
    const [com, setCom] = useState([]);

    useEffect(() => {
        getEvent();
    }, []);

    const getEvent = () => {
        setLoading(true);
        API_getevent({
            eventId: props.match.params.id,
        }).then(data => {
                         seteventName(data[0].eventDesc);
                         seteventDate(data[0].eventDate);
                         seteventDescFull(data[0].eventDescFull);
                         seteventCreateDate(data[0].createDate);
                         seteventAuthor(data[0].eventAuthor);
                         if (data[0].eventAuthor==localStorage.getItem('login')) {
                         setdis(false)
            }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        API_getcom({ devId: props.match.params.id, comType: 2 }).then(data => setCom(data));
    }, []);


    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Календарь', path: `/calendar` },
    { label: eventDate, path: `/viewcalendar/${eventDate}` },
    { label: eventName, path: `/event/${props.match.params.id}` },
    ]

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    comment: values.devComment,
                    devId: parseInt(props.match.params.id),
                    commType: 2,
                };
                API_addcomment(json)
                    .then(function(response) {
                        notification.success({ message: 'Комментарий добавлен' });
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const deleteEvent = () => {

        API_deleteEvent(props.match.params.id);

        props.history.push(`/viewcalendar/${eventDate}`);

        notification.success({ message: 'Удален' });

        window.location.reload();

    }


    return (
        <>

            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
              <Form className="che-form" onFinish={handleSubmit}>
              <div align="right"> <Link to={`/profile/${eventAuthor}`}><p>{'Добавлено пользователем:  '+eventAuthor+'  '+ moment(eventCreateDate).utc().format('DD.MM.YYYY HH:mm:ss')}</p></Link></div>
                <PageTitle title={eventName}/>
                <Form.Item style={{ marginBottom: 0 }}>
                <div align="right">
                    <Button disabled={dis} icon={<EditOutlined />} type="primary" onClick={() => props.history.push(`/eventedit/${props.match.params.id}`)}>
                            Редактировать
                    </Button>

                    <Button disabled={dis} icon={<DeleteOutlined />} type="danger" onClick={() => deleteEvent()} style={{ marginLeft: "10px" }}>
                            Удалить
                    </Button>
                </div>    
                </Form.Item>    
                <Form.Item >
                    <div dangerouslySetInnerHTML={{__html: devEventcFull}}/>
                </Form.Item>      
                <Form.Item style={{ marginBottom: 0 }}>
                    {com.map(comments => (
                        <div>
                            <Comment
                            author={<Link to={`/profile/${comments.author}`}><a>{comments.author}</a></Link>}
                            avatar={
                                <Link to={`/profile/${comments.author}`}>
                                    <Avatar
                                src={comments.profileAvatar}
                            />
                            </Link>
                            }
                            content={
                            <p>
                                {comments.comment}
                            </p>
                            }
                            datetime={
                            <Tooltip title={moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}</span>
                            </Tooltip>
                            }
                        />
                        </div>
                    ))}
                </Form.Item>    
                <Form.Item style={{ marginBottom: 0 }} name="devComment" rules={[{required: true, message: 'Введите комментарий'}]}>
                   <Input.TextArea placeholder="Комментарий" style={{width: '50%'}}/>
                </Form.Item>                
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button icon={<FormOutlined />} type="primary" htmlType="submit">
                            Отправить
                    </Button>
                </Form.Item>   
               </Form>
            </Spin>
        </>
    )
}

export default Event;