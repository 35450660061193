import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, notification, Input, Button, Spin, Comment, Avatar, Tooltip} from 'antd';
import { useState, useEffect } from 'react';
import { API_getcom, API_addcomment } from './../api/che';
import { Link } from 'react-router-dom'
import { API_getnews, API_deletenews, API_activatenews} from './../api/news';
import { FormOutlined, EditOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { API_checkreg } from './../api/registration';

var moment = require('moment');

const NewsSingle = (props) => {

    const [newsTitle, setnewsTitle] = useState(undefined);
    const [newsContent, setnewsContent] = useState(undefined);

    const [newsAuthor, setnewsAuthor] = useState(undefined);
    const [newsDate, setnewsDate] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [dis, setdis] = useState(false);
    const [god, setgod] = useState(false);
    const [activate, setactivate] = useState(true);
    const [com, setCom] = useState([]);

    const [update, setupdate] = useState(false);

    useEffect(() => {
            getNewsData();

            API_checkreg(localStorage.getItem('login'), 'null', 'null')
            .then(response => {
                if(response != undefined) {

                    if (localStorage.getItem('login') && response[0].email == 2) {
                        setgod(true)
                    } else {
                        setgod(false)
                    }

                }
            });
            
            API_getcom({ devId: props.match.params.id, comType: 4 }).then(data => setCom(data));

    }, [update]);

    const getNewsData = () => {
        const json = {
            newsId : props.match.params.id
        };
        API_getnews(json)
            .then(response => {

                if (response!=undefined) {
                    setnewsTitle(response[0].newsTitle);
                    setnewsContent(response[0].newsContent);
                    setnewsAuthor(response[0].newsAuthor);
                    setnewsDate(response[0].createdate);
                            if (response[0].newsAuthor==localStorage.getItem('login')) {
                                setdis(true)
                            }
                            if (response[0].newsType==3) {
                                setactivate(false)
                            }
                }       
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
    };

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Новости', path: `/news` },
    { label: props.match.params.id, path: `/viewnews/${props.match.params.id}` },
    ]

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    comment: values.devComment,
                    devId: parseInt(props.match.params.id),
                    commType: 4,
                };
                API_addcomment(json)
                    .then(function(response) {
                        setupdate(!update);
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const deletenews = () => {

        API_deletenews(props.match.params.id);

        props.history.push(`/`);

        notification.success({ message: 'Удален' });

        window.location.reload();

    }

    const activatenews = () => {

        API_activatenews(props.match.params.id);

        props.history.push(`/`);

        notification.success({ message: 'Активирован' });

        window.location.reload();

    }

    return (
        <>

            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit}>
            <div align="right"> <Link to={`/profile/${newsAuthor}`}><p>{'Добавлено пользователем:  '+newsAuthor+'  '+ moment(newsDate).utc().format('DD.MM.YYYY HH:mm:ss')}</p></Link></div>
            <Spin spinning={loading}>
              
                <Form.Item style={{ marginBottom: 40, marginTop: 20}}>

                <div align="right">
                    {god && !activate &&
                        <Button danger icon={<CheckOutlined />} onClick={() => activatenews() }>
                            Опубликовать
                        </Button>
                    }
                    {(god || dis) &&
                        <>
                        <Button icon={<EditOutlined />} type="primary" onClick={() => props.history.push(`/editnews/${props.match.params.id}`)} style={{ marginLeft: "10px" }}>
                                Редактировать
                        </Button>

                        <Button icon={<DeleteOutlined />} type="danger" onClick={() => deletenews()} style={{ marginLeft: "10px" }} >
                                Удалить
                        </Button>
                        </>
                    }
                    
                </div>  
                
                </Form.Item>
                <PageTitle title={<font color="#4E59F1"><a>{newsTitle}</a></font>}/>    
                <Form.Item >
                    <div dangerouslySetInnerHTML={{__html: newsContent}}/>
                </Form.Item>      
                <Form.Item style={{ marginBottom: 0 }}>
                    {com.map(comments => (
                        <div>
                            <Comment
                            author={<Link to={`/profile/${comments.author}`}><a>{comments.author}</a></Link>}
                            avatar={
                                <Link to={`/profile/${comments.author}`}>
                                    <Avatar
                                src={comments.profileAvatar}
                            />
                            </Link>
                            }
                            content={
                            <p>
                                {comments.comment}
                            </p>
                            }
                            datetime={
                            <Tooltip title={moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}</span>
                            </Tooltip>
                            }
                        />
                        </div>
                    ))}
                </Form.Item>    
                <Form.Item style={{ marginBottom: 0 }} name="devComment" rules={[{required: true, message: 'Введите комментарий'}]}>
                   <Input.TextArea placeholder="Комментарий" style={{width: '50%'}}/>
                </Form.Item>                
                <Form.Item style={{ marginTop: 10 }}>
                    <Button icon={<FormOutlined />} type="primary" htmlType="submit">
                            Отправить
                    </Button>
                </Form.Item>
            </Spin>
            </Form>
        </>
    )
}

export default NewsSingle;