import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, notification, Spin, message, Upload } from 'antd';
import { API_uploadfile} from './../api/che';
import { API_addnews } from './../api/news';
import { API_url } from './../api/const';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Новости', path: `/news` },
    { label: 'Добавить', path: `/addnews` },
    ]

const NewsAdd = (props) => {

    const [imageUrl, setimageUrl] = useState(undefined);

     function uploadImageCallBack(file) {
      return new Promise(
          (resolve, reject) => {

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

                const json = {
                    fileBody: fileSource,
                };

                API_uploadfile(json)
                .then(function(response) {
                    resolve({ data: { link: API_url+"/che/download/"+response.IdFile } });
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
            }
          }
        );
      }

    const [loading, setLoading] = useState(false);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    newsTitle: values.newsTitle,
                    newsDesc: values.newsDesc,
                    newsType: 2,
                    newsTitleImage: imageUrl,
                    newsContent: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                };
                API_addnews(json)
                    .then(function(response) {
                        notification.success({ message: 'Данные сохранены' });
                        props.history.push('/news');
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
      );

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status === 'done') {
        var reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => {

            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

            const json = {
                fileBody: fileSource,
            };

            API_uploadfile(json)
            .then(function(response) {
                setimageUrl(API_url+"/che/download/"+response.IdFile)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
        }
        setLoading(false);
        }
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };  
    
    const editorStyle = {
        border: '1px solid black',
        padding: '5px',
        borderRadius: '2px',
        width: '100%',
      };
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical'>
                <PageTitle title={"Добавить новость"}/>
                <Spin spinning={loading}>
                <Form.Item label="Название" style={{ marginBottom: 0 }} name="newsTitle" rules={[{required: true, message: 'Введите название'}]}>
                                   <Input placeholder="введите название" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Короткое описание" style={{ marginBottom: 0 }} name="newsDesc" rules={[{required: true, message: 'Введите описание'}]}>
                                   <Input.TextArea placeholder="короткое описание" maxLength="250"/>
                    </Form.Item>
                    <Upload 
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : 
                        <div>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div className="ant-upload-text">"Титульное" изображение</div>
                        </div>}
                    </Upload>

                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={setEditorState}
                        editorStyle={editorStyle}
                        toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: { uploadCallback: uploadImageCallBack,
                                previewImage: true,
                                defaultSize: {
                                height: 'auto',
                                width: '100%',
                        }},
                        }}
                    />
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default NewsAdd;