import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Button, Spin, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getevent } from './../api/calendar';
import { PlusOutlined} from '@ant-design/icons';

var moment = require('moment');

const columns = [
    {
        title: 'Событие',
        key: 'eventDesc',
        dataIndex: 'eventDesc',
        width: '60%',
    },
    {
        title: 'Место',
        key: 'eventPlace',
        dataIndex: 'eventPlace',
        width: '20%',
    },
    {
        title: 'Дата',
        width: '20%',
        align: 'center',
        render: row => {
            const date = moment(row.eventDateFull)
                .utc()
                .format('DD.MM.YYYY');
            const time = moment(row.eventDateFull)
                .utc()
                .format('HH:mm:ss');
            return (
                <>
                    <p style={{ fontSize: 14, fontWeight: 500 }}>{date}</p>
                    <p style={{ fontSize: 12, marginRight: 5, fontStyle: 'italic' }}>{time}</p>
                </>
            );
        },

    },
];

const KalendarDate = props => {

    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Календарь', path: `/calendar` },
        { label: props.match.params.date, path: `/viewcalendar/${props.match.params.date}` },
        ]

    const [loading, setLoading] = useState(false);
    const [eventdata, setEventdata] = useState([]);

    useEffect(() => {
        getEvent();
    }, []);


    const getEvent = () => {
        setLoading(true);
        API_getevent({
            eventDate: props.match.params.date,
        }).then(data => {
                setEventdata(data);
            })
            .finally(() => setLoading(false));
    };
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form">
                <PageTitle title={`События на ${props.match.params.date}`} />
                <div align="right">
                    <Button icon={<PlusOutlined />} onClick={() => props.history.push('/eventadd')} 
                    type="primary">
                    Добавить
                    </Button>
                </div>    
                <Spin spinning={loading}>
                    <Table
                        columns={columns}
                        dataSource={eventdata}
                        rowClassName="row-hover"
                        pagination={{
                            pageSize: 100,
                            position: 'both',
                        }}
                        onRow={getEventData => {
                            return {
                                onClick: () => props.history.push(`/event/${getEventData.eventId}`),
                            };
                        }}
                    />
                </Spin>
            </Form>    
        </>
    );
};

export default withRouter(KalendarDate);
