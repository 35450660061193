import React from 'react';
import { Form, Input, Button } from 'antd';
import { notification, Drawer, Modal, Spin, Select, Checkbox, Tooltip } from 'antd';
import { API_registration, API_checkreg } from './../api/registration';
import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { API_getDevVuz} from './../api/che';
import { QuestionCircleOutlined } from '@ant-design/icons';

const Registraton = props => {

    const [DrawerV, setDrawerV] = useState(true);
    const [loading, setLoading] = useState(false);
    const [btRegDis, setbtRegDis] = useState(true);
    const [idVuz, setidVuz] = useState(undefined);
    const [vuzdata, setvuzdata] = useState([]);

    var randomstring = Math.random().toString(36).slice(-8);

    const  chekemail = (rule, value, callback) => {
            API_checkreg(value, 'null', 'null')
            .then(response => {
                if (value && response[0].email != 0) {
                    callback('С такой почтой уже зарегистрировались, введите другой email');
                } else {
                    callback();
                }
            })
            .catch(() => {
                notification.error({ message: 'Произошла ошибка, пожалуйста попробуйте позже' });
            });
    };


    useEffect(() => {
        API_getDevVuz().then(data => setvuzdata(data));
    }, []);


    const Reg = values => {
                info()
                API_registration(values.email, randomstring, values.rpass, randomstring, values.rfio, idVuz, values.rdpost, values.rname, values.rlname, values.rphone, values.rlink, values.gmail)
                    .then(response => {
                        if (response.id==1) {
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(
                        setDrawerV(false)
                    );
    };

    function info() {
        Modal.info({
          title: 'Заявка',
          content: (
            <div>
              <p>Заявка отправлена. Вам будет выслано сообщение для подтверждения адреса электронной почты.</p>
            </div>
          ),
          onOk() {
            setDrawerV(false)
          },
        });
      }

      const onCheck = () => { 
            setbtRegDis(!btRegDis)
       }

    const DrawerClose = () => {
        setDrawerV(false)
      };
    
    const [form] = Form.useForm();

    const  compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('rpass')) {
            callback('введенные вами пароли не совпадают');
        } else {
            callback();
        }
        };


    return (
            <Drawer
            width={"80%"}
            title="Заявка"
            visible={DrawerV}
            onClose={DrawerClose}
            bodyStyle={{ paddingBottom: 80 }}
            >
            <Spin spinning={loading}>
            <Form onFinish={Reg} layout='vertical' form={form}>  
            <Form.Item label="Фамилия" name="rfio" rules={[{required: true, message: 'Пожалуйста введите отчество'}]} hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item label="Имя" name="rname" rules={[{required: true, message: 'Пожалуйста введите отчество'}]} hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item label="Отчество" name="rlname" rules={[{required: true, message: 'Пожалуйста введите отчество'}]} hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="Название ВУЗа" name="rvuz" rules={[{required: true, message: 'Пожалуйста выберите ВУЗ'}]} hasFeedback>
            <Select
                value={idVuz}
                onChange={setidVuz}
                style={{ width: '100%' }}
                placeholder="выберите ВУЗ"
                showSearch
                optionFilterProp="children"
                allowClear
            >
                {vuzdata.map(vuz => (
                    <Select.Option key={vuz.vuzshortname} value={vuz.vuzshortname}>
                        {vuz.vuzshortname}
                    </Select.Option>
                ))}
            </Select>
            </Form.Item>
            <Form.Item label="Должность" name="rdpost" rules={[{required: true, message: 'Пожалуйста введите номер телефона'}]} hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item label="Ссылка на свой профиль на официальном сайте вуза или другую страницу, где есть подтверждение занимаемой должности" name="rlink" rules={[{required: true, message: 'Пожалуйста введите ссылку'}]} hasFeedback>
                <Input type="url"/>
            </Form.Item>
            <Form.Item label="Номер телефона" name="rphone" rules={[{required: false, message: 'Пожалуйста введите номер телефона'}]} hasFeedback>
                <InputMask mask="+7 (999) 999-99-99" />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{required: true, message: 'Пожалуйтса введите ваш email'},{validator: chekemail}]} hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item label={<Tooltip title="Для работы с Google документами">Email привязанный к Google <QuestionCircleOutlined /></Tooltip>} name="gmail" hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item label="Пароль" name="rpass" rules={[{required: true, message: 'Пожалуйста введите пароль'}]} hasFeedback>
                <Input type="password" />
            </Form.Item>
            <Form.Item label="Повторите пароль" name="confirm" rules={[{required: true, message: 'Пожалуйста повторите ваш пароль'},{validator: compareToFirstPassword}]} hasFeedback>
                <Input.Password />
            </Form.Item>
            <div align="left">
                <font color="#0099ff"><a target="_blank" href="https://drive.google.com/file/d/1vDyHpK5fm-yW6992HPTuq6WqTZjRO-w_/view?usp=sharing">Политика в отношении обработки персональных данных</a></font>
            </div>
            <div align="left" class="divconf">
                <font color="#0099ff"><a target="_blank" href="https://drive.google.com/file/d/1B_cmrYd4rbhsNIbDwJekwPv3WjP_Spsm/view?usp=sharing">Положении о Совете ИТ-руководителей вузов России</a></font>
            </div>
            <div align="left">
            <Checkbox onChange={onCheck}>Ознакомился с политикой обработки персональных данных и положением о Совете ИТ-руководителей вузов России.</Checkbox>
            </div>
            <Button style={{ marginTop: "20px" }} type="primary" htmlType="submit" disabled={btRegDis}>
                Отправить заявку
            </Button>
            </Form>
            </Spin>
            </Drawer>
    );
};

export default Registraton;