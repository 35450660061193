import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, notification, Input, Button, Spin, Comment, Avatar, Tooltip} from 'antd';
import { useState, useEffect } from 'react';
import { API_getcom, API_addcomment } from './../api/che';
import { API_deleteforum, API_getforum } from './../api/forum';
import { Link } from 'react-router-dom'
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

var moment = require('moment');

const ForumSingle = (props) => {

    const [forumTitle, setforumTitle] = useState(undefined);
    const [forumDescFull, setforumDescFull] = useState(undefined);

    const [forumDate, setforumDate] = useState(undefined);
    const [forumAuthor, setforumAuthor] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [dis, setdis] = useState(true);

    const [com, setCom] = useState([]);

    useEffect(() => {
        API_getcom({ devId: props.match.params.id, comType: 3 }).then(data => setCom(data));
    }, []);

    useEffect(() => {
        getforumdata();
    }, []);


    const getforumdata = () => {
        setLoading(true);
        API_getforum({
            forumId: parseInt(props.match.params.id),
        }).then(response => {
            setforumTitle(response[0].forumTitle);
            setforumDescFull(response[0].forumDescFull);
            setforumDate(response[0].createDate);
            setforumAuthor(response[0].forumAuthor);
            if (response[0].forumAuthor==localStorage.getItem('login')) {
                setdis(false)
            }
})
            .finally(() => setLoading(false));
    };

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Форум', path: `/forum` },
    { label: props.match.params.id, path: `/viewforum/${props.match.params.id}` },
    ]

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    comment: values.devComment,
                    devId: parseInt(props.match.params.id),
                    commType: 3,
                };
                API_addcomment(json)
                    .then(function(response) {
                        notification.success({ message: 'Комментарий добавлен' });
                        window.location.reload();
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const deleteforum = () => {

        API_deleteforum(props.match.params.id);

        props.history.push(`/forum`);

        notification.success({ message: 'Удален' });

        window.location.reload();

    }

    return (
        <>

            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit}>
            <div align="right"> <Link to={`/profile/${forumAuthor}`}><p>{'Добавлено пользователем:  '+forumAuthor+'  '+ moment(forumDate).utc().format('DD.MM.YYYY HH:mm:ss')}</p></Link></div>    
            <PageTitle title={forumTitle}/>
            <Spin spinning={loading}>
                <Form.Item style={{ marginBottom: 0 }}>
                <div align="right">
                    <Button disabled={dis} icon={<DeleteOutlined />} type="danger" onClick={() => deleteforum()}>
                            Удалить
                    </Button>
                </div>    
                </Form.Item>    
                <Form.Item >
                    <div dangerouslySetInnerHTML={{__html: forumDescFull}}/>
                </Form.Item>      
                <Form.Item style={{ marginBottom: 0 }}>
                    {com.map(comments => (
                        <div>
                            <Comment
                            author={<Link to={`/profile/${comments.author}`}><a>{comments.author}</a></Link>}
                            avatar={
                                <Link to={`/profile/${comments.author}`}>
                                    <Avatar
                                src={comments.profileAvatar}
                            />
                            </Link>
                            }
                            content={
                            <p>
                                {comments.comment}
                            </p>
                            }
                            datetime={
                            <Tooltip title={moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(comments.comCreate).utc().format('YYYY-MM-DD HH:mm:ss')}</span>
                            </Tooltip>
                            }
                        />
                        </div>
                    ))}
                </Form.Item>    
                <Form.Item style={{ marginBottom: 0 }} name="devComment" rules={[{required: true, message: 'Введите комментарий'}]}>
                    <Input.TextArea placeholder="Комментарий" style={{width: '50%'}}/>
                </Form.Item>                
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button icon={<FormOutlined />} type="primary" htmlType="submit">
                            Отправить
                    </Button>
                </Form.Item>
            </Spin>
            </Form>
        </>
    )
}

export default ForumSingle;