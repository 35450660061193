import React from 'react';
import { Form, Input, Button, Modal, notification, Drawer, Spin } from 'antd';
import { API_forgetpassword } from './../api/changepassword';
import { useState } from 'react';
import { API_checkreg } from './../api/registration';

const ForgetPassword = props => {

    const [DrawerV, setDrawerV] = useState(true);
    const [loading, setLoading] = useState(false);

    const ForPass = values => {
        setLoading(true);
                API_forgetpassword(values.email)
                    .then(response => {
                        if (response.id==1) {
                            info()
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                        if (response.id==2) {
                            notification.error({ message: `Вы сегодня уже пытались восстановить пароль, попробуйте снова через несколько часов` });
                        }
                    })
                    .catch(
                        setDrawerV(false)
                    )
                    .finally(() => setLoading(false));
    };

    const  chekemail = (rule, value, callback) => {
        API_checkreg(value, 'null', 'null')
        .then(response => {
            if (value && response[0].email == 0) {
                callback('Пользователь с таким email не найден, введите другой адрес. Если вы не помните почту то напишите нам письмо che@s-vfu.ru');
            } else {
                callback();
            }
        })
        .catch(() => {
            notification.error({ message: 'Произошла ошибка, пожалуйста попробуйте позже' });
        });
    };

    const DrawerClose = () => {
        setDrawerV(false)
      };

    function info() {
        Modal.info({
          title: 'Восстановление логина/пароля',
          content: (
            <div>
              <p>Вам отправлено письмо пожалуйста проверьте почту</p>
            </div>
          ),
          onOk() {
            setDrawerV(false)
          },
        });
      }

    return (
        <Spin spinning={loading} size="large">
            <Drawer
            width={"80%"}
            title="Восстановление логина/пароля"
            visible={DrawerV}
            onClose={DrawerClose}
            bodyStyle={{ paddingBottom: 80 }}
            >
                <Form onFinish={ForPass} layout='vertical'>  
                <Form.Item label="Введите ваш E-mail, который вы указали при регистрации на сайте" name="email" rules={[{required: true, message: 'Пожалуйста введите ваш Email, который вы указали при регистрации на сайте'},{validator: chekemail}]} hasFeedback>
                <Input />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                        Восстановить доступ
                </Button>
                </Form>  
            </Drawer> 
        </Spin>    
    );
};

export default ForgetPassword;