import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Button, Spin, Form, Input, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getdev , API_getDevVuz} from './../api/che';
import { PlusOutlined} from '@ant-design/icons';

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, { label: 'Разработки', path: '/development' }];

const columns = [
    {
        title: 'ВУЗ',
        key: 'devVuz',
        dataIndex: 'devVuz',
        width: '20%',
        align: 'center',
    },
    {
        title: 'Название',
        key: 'devName',
        dataIndex: 'devName',
        width: '30%',
    },
    {
        title: 'Короткое описание',
        key: 'devDesc',
        dataIndex: 'devDesc',
        width: '40%',
    },
    {
        title: 'Дата публикации',
        width: '10%',
        align: 'center',
        render: row => {
            const date = moment(row.devCreated)
                .utc()
                .format('DD.MM.YYYY');
            const time = moment(row.devCreated)
                .utc()
                .format('HH:mm:ss');
            return (
                <>
                    <p style={{ fontSize: 14, fontWeight: 500 }}>{date}</p>
                    <p style={{ fontSize: 12, marginRight: 5, fontStyle: 'italic' }}>{time}</p>
                </>
            );
        },

    },
];

const Development = props => {
    const [loading, setLoading] = useState(false);
    const [devName, setdevName] = useState(undefined);
    const [devVuz, setdevVuz] = useState(undefined);
    const [vuzs, setVuzs] = useState([]);
    const [devdata, setDevdata] = useState([]);

    useEffect(() => {
        API_getDevVuz().then(data => setVuzs(data));
    }, []);

    useEffect(() => {
        getDev();
    }, [devName, devVuz]);


    const getDev = () => {
        setLoading(true);
        API_getdev({
            devName: devName,
            devVuz: devVuz,
        }).then(data => {
                    setDevdata(data);
            })
            .finally(() => setLoading(false));
    };
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            
            <Form className="che-form" layout='vertical'>
                <PageTitle title="Разработки" />
                <div align="right">
                    <Button icon={<PlusOutlined />} onClick={() => props.history.push('/developmentadd')} type="primary">
                        Добавить
                    </Button>
                </div>    
                <Form.Item label="Название">
                    <Input.Search onSearch={setdevName} placeholder="поиск по названию" />
                </Form.Item>
                <Form.Item label="ВУЗ">
                    <Select
                        value={devVuz}
                        onChange={setdevVuz}
                        placeholder="выберите"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                    >
                        {vuzs.map(vuz => (
                            <Select.Option key={vuz.vuzshortname} value={vuz.vuzshortname}>
                                {vuz.vuzshortname}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Spin spinning={loading}>
                    <Table
                        columns={columns}
                        dataSource={devdata}
                        rowClassName="row-hover"
                        pagination={{
                            pageSize: 100,
                            position: 'both',
                        }}
                        onRow={getDevData => {
                            return {
                                onClick: () => props.history.push(`/viewdevelopment/${getDevData.devID}`),
                            };
                        }}
                    />
                </Spin>
            </Form>
        </>
    );
};

export default withRouter(Development);
