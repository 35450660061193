import axios from './../utils/axios';
import { notification } from 'antd';

export const API_getforum = (json) => {
    let url = `che/getforum?`;

    if (json) {
        if (json.forumId) url += `&forumId=${json.forumId}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_addforum = json => {
    return axios({
        method: 'POST',
        url: `che/addforum`,
        data: {
            forumTitle: json.forumTitle,
            forumDescFull: json.forumDescFull,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deleteforum = forumId => {
    return axios({
        method: 'DELETE',
        url: `che/deleteforum/${forumId}`,
    });
};
