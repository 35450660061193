import React from 'react'
import { Form, Spin, List, Descriptions } from 'antd';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react'; 
import { API_getkey } from './../api/key';
import { API_getMembers } from './../api/profile';
import { Link } from 'react-router-dom'


const About = props => {

    const [loading, setLoading] = useState(false);
    const [membersdata, setmembersdata] = useState([]);
    const [memberCount, setmemberCount] = useState(undefined);

    const routes = [{ label: 'Главная', path: '/' }, { label: 'О нас', path: '/about' }];

    useEffect(() => {
        getMembers();
    }, []);

    const getMembers = () => {
        setLoading(true);
        API_getMembers().then(data => { 
            setmembersdata(data)
            setmemberCount(Object.keys(data).length);
        } )
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
                <Form className="che-form">
                <PageTitle title="О нас" />
                <div class="callinfo" align="justify">
                <pre className="pre-form"> 
{`Данный портал Совета ИТ-руководителей вузов России был задуман как инструмент для обмена опытом, совместной работы, хранения и передачи полезных наработок. Каждый день мы решаем схожие задачи, объединившись мы сможем легче найти пути их решения, эффективней взаимодействовать, в том числе с Минобрнауки, и более результативно осуществлять цифровизацию на местах. Предлагаем вместе развиваться и наполнять портал полезной информацией. Коротко о некоторых разделах портала:
- «Новости» - будут еженедельно пополняться обзором свежих новостей о цифровизации образования
- «Разработки» - здесь можно найти готовые решения других вузов и оставить свои.
- «Календарь мероприятий» - будет наполняться по мере появления событий, в него можно и нужно вносить мероприятия в сфере ИТ проводимые вашим вузом.
- «Форумы» - они и есть форумы) Участвуйте в обсуждении создавайте свои!
- «Файлы» - это Гугл-папки разбитые по темам и предназначенные для хранения нашего опыта. Сейчас они в стадии заполнения в том числе и полезными материалами из Телеграмм-канала. Присоединяйтесь, дальше будем наполнять вместе!)
- «Чат» - дублирует Telegram канал 'Руководители IT служб вузов'
- «Полезные ссылки» - полезные ссылки) Добавляйте свои!
- «О нас» - информация о членах Совета.
Любые предложения и пожелания только приветствуются!`}
                </pre>
                </div>
                <Form className="membersForm">
                    <PageTitle title={`Члены Совета (нас : ${memberCount})`} />

                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={membersdata}
                        pagination={{
                            pageSize: 100,
                          }}
                        renderItem={item => (
                            <List.Item
                            key={item.memberFio}
                            extra={
                            <img
                                width={200}
                                alt="logo"
                                src={item.memberAvatar}
                            />
                            }
                        >
                            <List.Item.Meta
                            title={<font color="#4E59F1"><Link to={`/profile/${item.memberLogin}`}><a>{item.memberFio+' '+item.memberName+' '+item.memberLname}</a></Link></font>}
                            />
                            <Descriptions>
                                <Descriptions.Item label="Email">{item.memberEmail}</Descriptions.Item>
                                <Descriptions.Item label="ВУЗ">{item.memberVuz}</Descriptions.Item>
                                <Descriptions.Item label="Должность">{item.memberPost}</Descriptions.Item>
                                <Descriptions.Item label="Номер телефона">{item.memberPhone}</Descriptions.Item>
                                <Descriptions.Item label="Ссылка на профиль"><a href={item.memberLink}>{item.memberLink}</a></Descriptions.Item>
                            </Descriptions>
                        </List.Item>)}
                    />
                </Form>
                </Form>
            </Spin>
        </>
        )
}

export default About;